import api from '../../axios'

export const dataPayment = (params) => {
    return new Promise((resolve, reject) => {
        api.get('/api/v1/payment-account/data-table', {
            params: {
                search: params.search,
                length: params.length,
                page: params.page,
                order: params.order,
                sort: params.sort,
                type: params.type ? params.type : 0,
            }
        }).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}


export const postPaymentAccount = (request) => {
    return new Promise((resolve, reject) => {
        api.post('/api/v1/payment-account', request).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}


export const putPaymentAccount = (id, request) => {
    return new Promise((resolve, reject) => {
        api.put('/api/v1/payment-account/'+id, request).then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}


export const dataBank = () => {
    return new Promise((resolve, reject) => {
        api.get('/api/v1/master/bank').then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}

export const dataEWallet = () => {
    return new Promise((resolve, reject) => {
        api.get('/api/v1/master/ewallet').then((r) => {
            resolve(r)
        }).catch((e) => {
            reject(e)
        })
    })
}